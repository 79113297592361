<template>
  <v-row>
    <v-col>
      <TableReadOnly :headerTable="headerTable" :items="paymentDetailsState"
        @clickRow="processItem">
        <template v-slot:actions="{ item, index }">
          <v-row>
            <v-col>
              <v-btn
                color="transparent"
                class="lighten-2 rounded-0 pa-0"
                @click="deletePayment(item)"
                icon
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </TableReadOnly>
    </v-col>
    <v-col class="py-2">
      <PaymentTab
        :tab="tab"
        @changeTab="changeTab"
        :data="creditBalance"
        :transStepId="tabDetails.trans_step_id"
      />

      <PaymentForm
        v-if="tab === 0"
        :item="selectedItem"
        :disabled="
          infoMessage &&
          infoMessage.disabledPanels &&
          infoMessage.disabledPanels.includes(2)
        "
      />

      <ApplyCreditForm v-else-if="tab === 1" />
      <PaymentDVContainerForm v-if="tab === 2" />

      <CreditHistoryData v-else-if="tab === 3" :data="creditBalance" />
    </v-col>
  </v-row>
</template>

<script>
import TableReadOnly from "@/components/General/TableReadOnly.vue";
import PaymentForm from "@/components/OM/Details/PaymentForm.vue";
import PaymentTab from "@/components/OM/Details/PaymentTab.vue";
import CreditHistoryData from "@/components/OM/CreditHistoryData.vue";
import PaymentDVContainerForm from "@/components/OM/Details/PaymentDVContainerForm.vue";
import ApplyCreditForm from "@/components/OM/Details/ApplyCreditForm.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";

export default {
  components: {
    TableReadOnly,
    PaymentForm,
    ApplyCreditForm,
    PaymentTab,
    CreditHistoryData,
    PaymentDVContainerForm,
  },
  computed: {
    ...mapGetters({
      docNo: "moduleOrders/docNo",
      tabDetails: ["moduleOrders/tabDetails"],
    }),
    ...mapState({
      paymentDetailsState: (state) =>
        state.moduleOrders.moduleDetails.paymentDetails.map((x) => {
          x.amount_paid = parseFloat(x.amount_paid).toFixed(2);
          x.payment_date = parseFromIsoToFormat(x.payment_date, "yyyy-LL-dd");
          return x;
        }),
      infoMessage: (state) => state.moduleOrders.moduleDetails.infoMessage,
      creditData: (state) => state.moduleOrders.moduleDetails.creditData,
      creditBalanceStore: (state) => state.moduleOrders.moduleDetails.creditBalance,
    }),
    headerTable() {
      return generateHeaderData(this.header);
    },
  },
  methods: {
    ...mapMutations({
      setCredits: "moduleOrders/setCredits",
      setCreditsTotal: "moduleOrders/setCreditsTotal",
    }),
    ...mapActions({
      getPaymentDetails: "moduleOrders/moduleDetails/getPaymentDetails",
      getCreditBalance: "moduleOrders/moduleDetails/getCreditBalance",
      getCustBalanceCredit: "moduleOrders/moduleDetails/getCustBalanceCredit",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
      deletePaymentStore: "moduleOrders/moduleDetails/deletePayment",
    }),
    changeTab(index) {
      this.tab = index;
    },
    async deletePayment(item) {
      console.log(item.id);
      await this.deletePaymentStore({ payment_id: item.id });
      this.updateDetails({ name: "" });
    },
    processItem(item) {
      this.selectedItem = item
    }
  },
  data() {
    return {
      header: [
        // { text: "#", value: "id" },
        { text: "Date", value: "payment_date" },
        { text: "Amount", value: "amount_paid" },
        { text: "Ref", value: "payment_no" },
        { text: "Remarks", value: "remarks" },
        { text: "Type", value: "type" },
        { text: "From", value: "invoice_no_apply_from" },
        { text: "", value: "actions" },
      ],
      items: [
        {
          id: "1",
          payment_date: "2022-04-20",
          amount_paid: "5$",
          payment_no: "#0001",
          remarks: "!!!!",
        },
      ],
      tab: 0,
      creditBalance: null,
      selectedItem: null
    };
  },
  watch: {
    async paymentDetailsState() {
      let result = await this.getCustBalanceCredit({
        custID: this.tabDetails.custID,
      });
      this.setCreditsTotal({
        amount: result.data.amount,
        balance: result.data.balance,
      });
      this.setCredits(result.data.credits);
    },
    creditBalanceStore() {
      this.creditBalance = this.creditBalanceStore;
    },
  },
  async created() {
    const { id } = await this.tabDetails;

    let result = await Promise.all([
      this.getPaymentDetails(id),
      this.getCreditBalance(),
      this.getCustBalanceCredit({
        custID: this.tabDetails.custID,
      }),
    ]);
    console.log(result);
    this.setCredits(result[2].data.credits);
    this.setCreditsTotal({
      amount: result[2].data.amount,
      balance: result[2].data.balance,
    });

    this.creditBalance = result[1].data || null;
  },
};
</script>

<style></style>
